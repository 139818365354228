import { UserViewModel } from "../../viewmodels/UserViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";
import MyAccountForm from "../../components/forms/my-account/MyAccountForm";
import MyPasswordForm from "../../components/forms/my-account/MyPasswordForm";

function MyAccount(props) {
  const { email, logout, name, updateAccountDetails } = props;

  const { updateAccountPassword } = UserViewModel(logout);

  return (
    <div>
      <div>
        <MyAccountForm
          updateAccountDetails={updateAccountDetails}
          userDetails={{
            name: name,
            email: email,
          }}
        />
      </div>
      <div>
        <MyPasswordForm updateAccountPassword={updateAccountPassword} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    updateAccountDetails: (payload) =>
      dispatch(actions.updateAccountDetails(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAccount)
);
