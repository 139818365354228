import React from "react";

export default function Input(props) {
  const {
    classes,
    label,
    name,
    onChange,
    type,
    value,
    labelOn,
    error,
    explanation,
    ...other
  } = props;

  return (
    <div>
      {labelOn ? (
        <div className="flex space-x-2">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-900"
          >
            {label}
          </label>
        </div>
      ) : null}
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id={name}
          className={
            classes
              ? classes
              : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
          }
          value={value}
          onChange={onChange}
          {...other}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
