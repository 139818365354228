import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";
import PricingVariableForm from "../components/forms/pricingVariables/PricingVariableForm";
import { updateObjectInArray } from "../shared/utility";

import { SimpleNotificationType } from "../components/notifications/SimpleNotification";

export function PricingVariablesViewModel(logout) {
  const [loading, setLoading] = useState(true);
  const [popupModalOpen, setPopupModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [pricingVariables, setPricingVariables] = useState({
    data: [],
  });

  const userId = localStorage.getItem("id");

  const getPricingVariables = async function (search, page) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `pricingVariables?page=${page}&search=${search}`
    );

    setLoading(false);
    if (callResult.success) {
      setPricingVariables(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const upcatePricingVariable = async function (
    pricingVariable,
    values,
    index
  ) {
    let callResult = await makePostApiCall(
      `pricingVariables/${pricingVariable.id}`,
      values
    );

    if (callResult.success) {
      const pricingVariablesData = [...pricingVariables.data];
      const updatedPricingVariable = {
        ...values,
      };

      const updatePayload = {
        index: index,
        item: updatedPricingVariable,
      };

      const updatedPricingVariables = updateObjectInArray(
        pricingVariablesData,
        updatePayload
      );

      const newPricingVariables = {
        ...pricingVariables,
        data: updatedPricingVariables,
      };

      setPricingVariables(newPricingVariables);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "PricingVariable updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const viewPricingVariableDetails = async function (pricingVariable, index) {
    setSlideOver({
      childComponent: (
        <PricingVariableForm
          index={index}
          isEdit={true}
          pricingVariable={pricingVariable}
          setOpen={setSlideOver}
          actionCall={upcatePricingVariable}
        />
      ),
      open: true,
    });
  };

  const addPricingVariable = async function (values) {
    let callResult = await makePostApiCall(`addPricingVariable`, values);

    if (callResult.success) {
      getPricingVariables("", 0);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "New pricingVariable added",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddNewPricingVariable = () => {
    setSlideOver({
      childComponent: (
        <PricingVariableForm
          actionCall={addPricingVariable}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  return {
    getPricingVariables,
    loading,
    openAddNewPricingVariable,
    setPricingVariables,
    showSimpleNoti,
    slideOver,
    pricingVariables,
    viewPricingVariableDetails,
    setShowSimpleNoti,
    setSlideOver,
    popupModalOpen,
    setPopupModalOpen,
  };
}
