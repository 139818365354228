export default function AlreadyScrapedProducts(props) {
  const { header, text } = props;

  return (
    <div>
      <div className="flex sm:items-center">
        <div className="flex-auto">
          <h3 className="mb-4 mt-2 text-gray-500">{header}</h3>
          <p className="mb-4 mt-2 text-gray-500">{text}</p>
        </div>
      </div>
    </div>
  );
}
