import { useEffect, useState } from "react";
import { useForm } from "../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";

const initialFValues = {
  title_template: 0,
  description_template: 0,
  gpt_version: "gpt-4",
  role: "assistant",
  temperature: 0.2,
  top_p: 0.3,
  max_tokens: 250,
};

export default function RunChatGptForm(props) {
  const {
    chatGptOptions,
    setOpen,
    actionCall,
    partNumber,
    partNumberActionCall,
  } = props;

  const [chatGptInProgress, setChatGptInProgress] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setChatGptInProgress(true);
    if (partNumber) {
      partNumberActionCall(values, partNumber);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    var title_template = 0;
    var description_template = 0;
    chatGptOptions.forEach((option) => {
      if (option.is_default === "description_template") {
        description_template = option.value;
      } else if (option.is_default === "title_template") {
        title_template = option.value;
      }
    });

    setValues({
      ...values,
      title_template: title_template,
      description_template: description_template,
    });
  }, []);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Run Chat Gpt {partNumber ? `for ${partNumber}` : null}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Title Template
                  </label>
                </div>
                <SelectMenu
                  handleInputChange={(e) => handleInputChange(e)}
                  name="title_template"
                  options={chatGptOptions}
                  width="w-48"
                  is_default={true}
                />
              </div>
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Description Template
                  </label>
                </div>
                <SelectMenu
                  handleInputChange={(e) => handleInputChange(e)}
                  name="description_template"
                  options={chatGptOptions}
                  width="w-48"
                  is_default={true}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Chat Gpt version to use"
                  name="gpt_version"
                  onChange={handleInputChange}
                  value={values.gpt_version}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Role"
                  name="role"
                  onChange={handleInputChange}
                  value={values.role}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Temperature"
                  name="temperature"
                  onChange={handleInputChange}
                  value={values.temperature}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Top p"
                  name="top_p"
                  onChange={handleInputChange}
                  value={values.top_p}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Max tokens"
                  name="max_tokens"
                  onChange={handleInputChange}
                  value={values.max_tokens}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          {chatGptInProgress ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Run"
          )}
        </button>
      </div>
    </div>
  );
}
