import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";
import ChatGptForm from "../components/forms/chatGpt/ChatGptForm";
import { updateObjectInArray } from "../shared/utility";

import { SimpleNotificationType } from "../components/notifications/SimpleNotification";

export function ChatGptTemplatesViewModel(logout) {
  const [loading, setLoading] = useState(true);
  const [popupModalOpen, setPopupModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [templates, setTemplates] = useState({
    data: [],
  });

  const userId = localStorage.getItem("id");

  const getTemplates = async function (search, page) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `getGptTemplates?page=${page}&search=${search}`
    );

    setLoading(false);
    if (callResult.success) {
      setTemplates(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateTemplate = async function (template, values, index) {
    let callResult = await makePostApiCall(
      `chatGptTemplates/${template.id}`,
      values
    );

    if (callResult.success) {
      // const templatesData = [...templates.data];
      // const updatedTemplate = {
      //   ...values,
      // };

      // const updatePayload = {
      //   index: index,
      //   item: updatedTemplate,
      // };

      // const updatedTemplates = updateObjectInArray(
      //   templatesData,
      //   updatePayload
      // );

      // const newTemplates = { ...templates, data: updatedTemplates };

      // setTemplates(newTemplates);
      getTemplates("", 0);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Template updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const viewTemplateDetails = async function (template, index) {
    setSlideOver({
      childComponent: (
        <ChatGptForm
          index={index}
          isEdit={true}
          template={template}
          setOpen={setSlideOver}
          actionCall={updateTemplate}
        />
      ),
      open: true,
    });
  };

  const addTemplate = async function (values) {
    let callResult = await makePostApiCall(`addGptTemplate`, values);

    if (callResult.success) {
      getTemplates("", 0);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "New template added",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddNewTemplate = () => {
    setSlideOver({
      childComponent: (
        <ChatGptForm actionCall={addTemplate} setOpen={setSlideOver} />
      ),
      open: true,
    });
  };

  return {
    getTemplates,
    loading,
    openAddNewTemplate,
    setTemplates,
    showSimpleNoti,
    slideOver,
    templates,
    viewTemplateDetails,
    setShowSimpleNoti,
    setSlideOver,
    popupModalOpen,
    setPopupModalOpen,
  };
}
