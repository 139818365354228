import {
  ArchiveBoxIcon,
  ComputerDesktopIcon,
  CubeIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname) {
  var title;
  var routes;
  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Products",
      href: "/products",
      icon: ArchiveBoxIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Chat GPT",
      href: "/chat-gpt",
      icon: ComputerDesktopIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Categories",
      href: "/categories",
      icon: CubeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Pricing Variables",
      href: "/pricingvar",
      icon: CurrencyDollarIcon,
      current: false,
      showInMenu: true,
    },
  ];

  routes.forEach((route) => {
    if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
