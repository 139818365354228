import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function SelectMenu(props) {
  const {
    handleInputChange,
    name,
    options,
    selectedOptionValue,
    title,
    width,
    is_default,
  } = props;
  const [selected, setSelected] = useState(
    options.length > 0 ? options[0] : null
  );

  useEffect(() => {
    if (is_default) {
      options.forEach((option) => {
        if (option.is_default === name) {
          setSelected(option);
        }
      });
    } else if (selectedOptionValue) {
      options.forEach((option) => {
        if (option.value == selectedOptionValue) {
          setSelected(option);
        }
      });
    }
  }, [options, selectedOptionValue, is_default]);

  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        setSelected(e);
        handleInputChange(convertToDefEventPara(name, e.value));
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {title}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                width,
                "relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              )}
            >
              <span className="block truncate">
                {selected ? selected.label : null}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((selectOption) => (
                  <Listbox.Option
                    key={selectOption.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={selectOption}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {selectOption.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
