import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";
import CategoryForm from "../components/forms/categories/CategoryForm";
import { updateObjectInArray } from "../shared/utility";

import { SimpleNotificationType } from "../components/notifications/SimpleNotification";

export function CategoriesViewModel(logout) {
  const [loading, setLoading] = useState(true);
  const [popupModalOpen, setPopupModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [categories, setCategories] = useState({
    data: [],
  });

  const userId = localStorage.getItem("id");

  const getCategories = async function (search, page) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `categories?page=${page}&search=${search}`
    );

    setLoading(false);
    if (callResult.success) {
      setCategories(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const upcateCategory = async function (category, values, index) {
    let callResult = await makePostApiCall(`categories/${category.id}`, values);

    if (callResult.success) {
      const categorysData = [...categories.data];
      const updatedCategory = {
        ...values,
      };

      const updatePayload = {
        index: index,
        item: updatedCategory,
      };

      const updatedCategories = updateObjectInArray(
        categorysData,
        updatePayload
      );

      const newCategories = { ...categories, data: updatedCategories };

      setCategories(newCategories);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Category updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const viewCategoryDetails = async function (category, index) {
    setSlideOver({
      childComponent: (
        <CategoryForm
          index={index}
          isEdit={true}
          category={category}
          setOpen={setSlideOver}
          actionCall={upcateCategory}
        />
      ),
      open: true,
    });
  };

  const addCategory = async function (values) {
    let callResult = await makePostApiCall(`addCategory`, values);

    if (callResult.success) {
      getCategories("", 0);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "New category added",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddNewCategory = () => {
    setSlideOver({
      childComponent: (
        <CategoryForm actionCall={addCategory} setOpen={setSlideOver} />
      ),
      open: true,
    });
  };

  return {
    getCategories,
    loading,
    openAddNewCategory,
    setCategories,
    showSimpleNoti,
    slideOver,
    categories,
    viewCategoryDetails,
    setShowSimpleNoti,
    setSlideOver,
    popupModalOpen,
    setPopupModalOpen,
  };
}
