import { useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import Dropzone from "react-dropzone";

import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function ImportMediaDropzone(props) {
  const { fileName, actionCall, setOpen } = props;

  const [error, setError] = useState(null);

  return (
    <div>
      <div>
        <div className="flex sm:items-center">
          <div className="flex-auto">
            <p className="mb-4 mt-2 text-gray-500">
              Upload CSV to import products
            </p>
          </div>
          <div className="sm:ml-16 sm:mt-0 flex-none my-auto sm:mb-0">
            <button
              type="button"
              className="rounded-md bg-white text-gray-900 hover:text-gray-500 "
              onClick={() =>
                setOpen({
                  childComponent: null,
                  open: false,
                })
              }
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <Dropzone
          onDrop={(acceptedFile) => {
            if (acceptedFile.length > 0) {
              actionCall(convertToDefEventPara(fileName, acceptedFile));
            } else {
              setError("Could not upload file, please try again.");
            }
          }}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps({
                  className:
                    "relative block w-full bg-blue-50 border-2 border-blue-300 border-solid rounded-lg p-5 text-center hover:border-gray-400 cursor-pointer",
                })}
              >
                <input {...getInputProps()} />
                <div className="text-center">
                  <p className="sm:hidden mt-3 text-gray-700 family-didact">
                    Tap here to upload.
                  </p>
                  <p className="hidden sm:block mt-3 text-gray-700 family-didact">
                    Click here to upload csv
                  </p>
                  <div className="mt-2 mx-auto flex items-center justify-center text-sm"></div>
                  <p className="text-gray-700 text-sm">...</p>
                  <br /> <br />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div className={classNames(error ? "text-center p-4" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">{error}</h3>
      </div>
    </div>
  );
}
