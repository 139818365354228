import axiosRegular from "../axios";

export async function makePostApiCall(endPoint, payload, contentType) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
      "content-type": contentType ? contentType : "application/json",
    },
  };

  return axiosRegular
    .post(endPoint, payload, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Oops",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}

export async function makeGetApiCall(endPoint) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  return axiosRegular
    .get(endPoint, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Oops",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}
