import { useEffect, useRef } from "react";
import { PricingVariablesViewModel } from "../../viewmodels/PricingVariablesViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import PopupModal from "../../components/modals/PopupModal";
import SimpleNotification from "../../components/notifications/SimpleNotification";

function PricingVar(props) {
  const { logout } = props;
  const {
    getPricingVariables,
    openAddNewPricingVariable,
    showSimpleNoti,
    slideOver,
    pricingVariables,
    viewPricingVariableDetails,
    setShowSimpleNoti,
    setSlideOver,
    popupModalOpen,
    setPopupModalOpen,
  } = PricingVariablesViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getPricingVariables(null, currentPage);
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getPricingVariables(searchRef.current.value, currentPage);
  };

  const handlePagination = (newPage) => {
    currentPage = newPage;
    getPricingVariables(searchRef.current.value, newPage);
  };

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              {/* search starts */}
              <div className="mt-4 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchInput"
                    id="searchInput"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by pricingVariable name"
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => openAddNewPricingVariable()}
                >
                  Add Variable
                </button>
              </div>
              {/* search ends */}
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Value
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {pricingVariables.data.map((pricingVariable, index) => (
                        <tr key={pricingVariable.id}>
                          <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-6">
                            {pricingVariable.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {pricingVariable.value}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={() => {
                                viewPricingVariableDetails(
                                  pricingVariable,
                                  index
                                );
                              }}
                              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Edit
                            </button>
                            {/* <button
                                onClick={() => {
                                  deletePricingVariable(pricingVariable.id);
                                }}
                                className="ml-5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              >
                                Delete
                              </button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white px-4 py-3 mt-5 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">{pricingVariables.from}</span>{" "}
                  to <span className="font-medium">{pricingVariables.to}</span>{" "}
                  of{" "}
                  <span className="font-medium">{pricingVariables.total}</span>{" "}
                  results
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleNotification
        show={showSimpleNoti.open}
        setShow={setShowSimpleNoti}
        title={showSimpleNoti.title}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <PopupModal
        childComponent={popupModalOpen.childComponent}
        open={popupModalOpen.open}
        setOpen={setPopupModalOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PricingVar)
);
