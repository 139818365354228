import { useEffect, useRef, useState } from "react";
import { ProductsViewModel } from "../../viewmodels/ProductsViewModel";
import {
  MagnifyingGlassIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import PopupModal from "../../components/modals/PopupModal";
import SimpleNotification from "../../components/notifications/SimpleNotification";
import SelectMenuMultiple from "../../components/controls/SelectMenuMultiple";
import SelectMenu from "../../components/controls/SelectMenu";

import Pagination from "../../components/pagination/Pagination";
import ProductActionButtons from "./ProductActionButtons";

function Products(props) {
  const { logout } = props;

  const {
    chatGptFilter,
    chatGptFilterOptions,
    clearFilters,
    deleteProduct,
    exportCsv,
    getProducts,
    getMakeFilterOptions,
    getCategoryFilterOptions,
    loading,
    makeFilterOptions,
    categoryFilterOptions,
    scrapeFilterOptions,
    shopifyFilterOptions,
    pageSizeOptions,
    onAllInputSelected,
    onInputSelected,
    openAddNewProduct,
    openImportProduct,
    products,
    scrapeInProgress,
    scrapeSelectedProducts,
    selectAllBoxSelected,
    setShowSimpleNoti,
    setSlideOver,
    setPopupModalOpen,
    showSimpleNoti,
    slideOver,
    popupModalOpen,
    viewProductDetails,
    setMakeFilter,
    setCategoryFilter,
    setChatGptFilter,
    setScrapeFilter,
    setShopifyFilter,
    setPageSize,
    openChatGptForm,
    getGptTemplatesForDropdown,
    makeFilter,
    categoryFilter,
    scrapeFilter,
    shopifyFilter,
    pageSize,
    markShopifyAdded,
    syncModelsUsedAndReplacesWithShopify,
    metadataInSync,
    syncMetadataFilterOptions,
    syncMetadataFilter,
    setSyncMetadataFilter,
    fetchNewProductsFromShopify,
    shopifyDataInProgress,
    manuallyUpdateShopifyInventoryAndPrice,
    shopifyPriceUpdateInProgress,
  } = ProductsViewModel(logout);

  const actionButtonItems = [
    { name: "Add product", onClick: openAddNewProduct },
    { name: "Import", onClick: openImportProduct },
  ];

  const [sortBy, setSortBy] = useState({
    sortBy: "products.partNumber",
    orderByDESC: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getProducts(null, currentPage);
    getMakeFilterOptions();
    getCategoryFilterOptions();
    getGptTemplatesForDropdown();
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getProducts(searchRef.current.value, currentPage);
  };

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
    getProducts(
      searchRef.current.value,
      newPage,
      sortBy.sortBy,
      sortBy.orderByDESC,
      makeFilter,
      categoryFilter,
      scrapeFilter,
      shopifyFilter,
      chatGptFilter,
      syncMetadataFilter,
      pageSize
    );
  };

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              {/* search starts */}
              <div className="mt-1 flex rounded-md">
                <div>
                  <SelectMenu
                    handleInputChange={(e) => {
                      setPageSize(e.target.value);
                      getProducts(
                        searchRef.current.value,
                        currentPage,
                        "products.partNumber",
                        !sortBy.orderByDESC,
                        makeFilter,
                        categoryFilter,
                        scrapeFilter,
                        shopifyFilter,
                        chatGptFilter,
                        syncMetadataFilter,
                        e.target.value
                      );
                    }}
                    name="pageSize"
                    options={pageSizeOptions}
                    selectedOptionValue={20}
                    width="w-24"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenuMultiple
                    handleInputChange={(e) => {
                      setMakeFilter(e);
                    }}
                    name="makeFilter"
                    options={makeFilterOptions}
                    selectedOptionValue={makeFilter}
                    width="w-48"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenuMultiple
                    handleInputChange={(e) => setCategoryFilter(e)}
                    name="categoryFilter"
                    options={categoryFilterOptions}
                    selectedOptionValue={categoryFilter}
                    width="w-48"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenuMultiple
                    handleInputChange={(e) => setScrapeFilter(e)}
                    name="scrapeFilter"
                    options={scrapeFilterOptions}
                    selectedOptionValue={scrapeFilter}
                    width="w-48"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenuMultiple
                    handleInputChange={(e) => setShopifyFilter(e)}
                    name="shopifyFilter"
                    options={shopifyFilterOptions}
                    selectedOptionValue={shopifyFilter}
                    width="w-48"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenu
                    handleInputChange={(e) => setChatGptFilter(e.target.value)}
                    name="chatGptFilter"
                    options={chatGptFilterOptions}
                    selectedOptionValue={chatGptFilter}
                    width="w-48"
                  />
                </div>
                <div className="ml-2">
                  <SelectMenu
                    handleInputChange={(e) =>
                      setSyncMetadataFilter(e.target.value)
                    }
                    name="syncMetadataFilter"
                    options={syncMetadataFilterOptions}
                    selectedOptionValue={syncMetadataFilter}
                    width="w-48"
                  />
                </div>
                {/* <div className="ml-2">
                  <SelectMenu
                    handleInputChange={onFilterChanged}
                    name="filterBy"
                    options={filterOptions}
                    selectedOptionValue={filterOptions[0].value}
                    width="w-48"
                  />
                </div> */}
                <button
                  type="button"
                  className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() =>
                    getProducts(
                      searchRef.current.value,
                      0,
                      sortBy.sortBy,
                      sortBy.orderByDESC,
                      makeFilter,
                      categoryFilter,
                      scrapeFilter,
                      shopifyFilter,
                      chatGptFilter,
                      syncMetadataFilter,
                      pageSize
                    )
                  }
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
              </div>
              <div className="mt-4 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by name or email"
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <ProductActionButtons items={actionButtonItems} />
                <button
                  type="button"
                  className="ml-5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 flex"
                  onClick={() => scrapeSelectedProducts()}
                >
                  {scrapeInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Scrape selected
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 flex"
                  onClick={() => fetchNewProductsFromShopify()}
                >
                  {shopifyDataInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Fetch Shop Id
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 flex"
                  onClick={() => syncModelsUsedAndReplacesWithShopify()}
                >
                  {metadataInSync ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Sync Meta
                </button>

                <button
                  type="button"
                  className="ml-5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 flex"
                  onClick={() => manuallyUpdateShopifyInventoryAndPrice()}
                >
                  {shopifyPriceUpdateInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Update Prices
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                  onClick={() => exportCsv()}
                >
                  Export
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => markShopifyAdded()}
                >
                  Shopify Added
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={() => openChatGptForm()}
                >
                  Chat Gpt
                </button>
              </div>
              {/* search ends */}
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              {/* <div
                className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 text-center"
                onClick={() => {
                  console.log("select all filtered clicked");
                }}
              >
                Select all
              </div> */}
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          style={{ width: "4%" }}
                        >
                          <input
                            id="selectAllBox"
                            aria-describedby="selectAllBox"
                            name="selectAllBox"
                            type="checkbox"
                            checked={selectAllBoxSelected}
                            className="mt-1 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onClick={(e) =>
                              onAllInputSelected(e.target.checked)
                            }
                          />
                        </th>
                        <th
                          scope="col"
                          className="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "3%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.partNumber",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.partNumber",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Name
                            {sortBy.orderByDESC &&
                            sortBy.sort === "products.partNumber" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.make",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.make",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Make
                            {sortBy.orderByDESC &&
                            sortBy.sort === "products.make" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "categoryName",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "categoryName",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Category
                            {sortBy.orderByDESC &&
                            sortBy.sort === "categoryName" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.status",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.status",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Scrape result
                            {sortBy.orderByDESC &&
                            sortBy.sort === "products.status" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.title, products.description",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.title, products.description",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            ChatGpt status
                            {sortBy.orderByDESC &&
                            sortBy.sort ===
                              "products.title, products.description" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.shopify_status",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.shopify_status",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Shopify status
                            {sortBy.orderByDESC &&
                            sortBy.sort === "products.shopify_status" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          style={{ width: "15%" }}
                          onClick={() => {
                            setSortBy({
                              sort: "products.shopify_metadata_status",
                              orderByDESC: !sortBy.orderByDESC,
                            });
                            getProducts(
                              searchRef.current.value,
                              0,
                              "products.shopify_metadata_status",
                              !sortBy.orderByDESC,
                              makeFilter,
                              categoryFilter,
                              scrapeFilter,
                              shopifyFilter,
                              chatGptFilter,
                              syncMetadataFilter,
                              pageSize
                            );
                          }}
                        >
                          <div className="flex">
                            Shopify Metadata status
                            {sortBy.orderByDESC &&
                            sortBy.sort ===
                              "products.shopify_metadata_status" ? (
                              <ArrowDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          style={{ width: "15%" }}
                        ></th>
                      </tr>
                    </thead>
                    {loading ? (
                      <div>
                        <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                          <div className="animate-pulse flex space-x-4">
                            Loading
                          </div>
                        </div>
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {products.data.map((product, index) => (
                          <tr key={product.partNumber}>
                            <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-6">
                              <input
                                id="selectBox"
                                aria-describedby="selectBox"
                                name="selectBox"
                                type="checkbox"
                                checked={product.isChecked}
                                className="mt-1 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onClick={(e) =>
                                  onInputSelected(
                                    e.target.checked,
                                    product.partNumber,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td className="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">
                              ({product.partNumber}) - {product.partName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.make}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.categoryName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.statusText}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.chatgpt_status_text}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.shopify_status_text}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {product.shopify_metadata_status_text}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <button
                                onClick={() => {
                                  viewProductDetails(product.partNumber, index);
                                }}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                View
                              </button>
                              <button
                                onClick={() => {
                                  deleteProduct(product.partNumber);
                                }}
                                className="ml-5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white px-4 py-3 mt-5 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{products.from}</span>{" "}
                  to <span className="font-medium">{products.to}</span> of{" "}
                  <span className="font-medium">{products.total}</span> results
                </p>
              </div>

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={products.total}
                pageSize={pageSize}
                onPageChange={(page) => handlePagination(page)}
              />
            </div>
          </div>
        </div>
      </div>
      <SimpleNotification
        show={showSimpleNoti.open}
        setShow={setShowSimpleNoti}
        title={showSimpleNoti.title}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <PopupModal
        childComponent={popupModalOpen.childComponent}
        open={popupModalOpen.open}
        setOpen={setPopupModalOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);
