import { useEffect, useState } from "react";
import { useForm } from "../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";

const initialFValues = {
  name: "",
  description: "",
  title_default: 1,
  description_default: 1,
};

const yesNoOptions = [
  { value: 1, label: "No" },
  { value: 2, label: "Yes" },
];

export default function ChatGptForm(props) {
  const { index, isEdit, template, setOpen, actionCall } = props;

  const [formResult, setFormResult] = useState("");

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(template, values, index);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (isEdit) {
      var title_default = 1;
      var description_default = 1;
      if (template.is_default === "description_template") {
        description_default = 2;
      } else if (template.is_default === "title_template") {
        title_default = 2;
      }

      setValues({
        ...template,
        title_default: title_default,
        description_default: description_default,
      });
    }
  }, [template, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Edit template" : "Add template"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Name"
                  name="name"
                  onChange={handleInputChange}
                  value={values.name}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Description
                  </label>
                </div>
                <div className="mt-1">
                  <textarea
                    label="Description"
                    name="description"
                    onChange={handleInputChange}
                    value={values.description}
                    labelOn={true}
                    rows={4}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Default for title?
                  </label>
                </div>
                <SelectMenu
                  handleInputChange={(e) => handleInputChange(e)}
                  name="title_default"
                  options={yesNoOptions}
                  width="w-48"
                  selectedOptionValue={values.title_default}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Default for description?
                  </label>
                </div>
                <SelectMenu
                  handleInputChange={(e) => handleInputChange(e)}
                  name="description_default"
                  options={yesNoOptions}
                  width="w-48"
                  selectedOptionValue={values.description_default}
                />
              </div>
            </div>
          </div>
          <div className="mt-3 grid grid-cols-1 gap-4">
            <div>
              <p>{formResult}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
