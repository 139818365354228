import { useEffect, useState } from "react";
import { useForm } from "../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";

const initialFValues = {
  partNumber: "",
  make: "",
  partName: "",
  title: "",
  description: "",
  image_src_url: "",
  homedepot_url: "",
  amre_url: "",
  vv_appliances_url: "",
  categoryName: "",
  brands: "",
  replacedPartNumber: "",
  models_fit: "",
  notes: "",
};

export default function ProductDetailsForm(props) {
  const {
    actionCall,
    categoryFilterOptions,
    index,
    isEdit,
    product,
    scrapeProduct,
    setOpen,
    openChatGptForm,
    syncSingleItemWithShopify,
  } = props;

  const [metadataInSync, setMetadataInSync] = useState(false);
  const [scrapeInProgress, setScrapeInProgress] = useState(false);
  const [formResult, setFormResult] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("partNumber" in fieldValues) {
      temp.partNumber = null;
      if (fieldValues.partNumber === "") {
        temp.partNumber = "Please fill out field.";
      }
    }

    if ("make" in fieldValues) {
      temp.make = null;
      if (fieldValues.make === "") {
        temp.make = "Please fill out field.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(product, values, index);
    } else {
      if (validate()) {
        console.log("validate is true");
        actionCall(values);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setValues(product);
    }
  }, [product, setValues]);

  const startScrape = async function (partNumber) {
    setScrapeInProgress(true);
    let callResult = await scrapeProduct(partNumber);
    setFormResult("Scrape finished");
    setScrapeInProgress(false);
  };

  const starMetadataSync = async function (partNumber) {
    setMetadataInSync(true);
    let callResult = await syncSingleItemWithShopify(partNumber);
    setFormResult("Metadata sync finished");
    setMetadataInSync(false);
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Edit product" : "Add product"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Part Number"
                  name="partNumber"
                  onChange={handleInputChange}
                  value={values.partNumber}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.partNumber}
                />
              </div>
              <div>
                <Input
                  label="Make"
                  name="make"
                  onChange={handleInputChange}
                  value={values.make}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.make}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Scraped Name"
                  name="partName"
                  onChange={handleInputChange}
                  value={values.partName}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Title"
                  name="title"
                  onChange={handleInputChange}
                  value={values.title}
                  labelOn={true}
                  disabled={values.shopify_status === 2}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Category
                  </label>
                </div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="category"
                  options={categoryFilterOptions}
                  width="w-48"
                  selectedOptionValue={values.category}
                />
              </div>
              <div>
                <Input
                  label="Brands"
                  name="brands"
                  onChange={handleInputChange}
                  value={values.brands}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Description
                  </label>
                </div>
                <div className="mt-1">
                  <textarea
                    label="Description"
                    name="description"
                    onChange={handleInputChange}
                    value={values.description}
                    labelOn={true}
                    rows={4}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
                  />
                </div>
              </div>
              <div>
                <Input
                  label="Shopify id"
                  name="shopify_id"
                  onChange={handleInputChange}
                  value={values.shopify_id}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
                <a
                  type="submit"
                  className="inline-flex justify-center mt-3 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-500 items-center"
                  href={
                    "https://admin.shopify.com/store/partsmart-2951/products/" +
                    values.shopify_id
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Management Url
                </a>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="notes"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Notes
                  </label>
                </div>
                <div className="mt-1">
                  <textarea
                    label="Notes"
                    name="notes"
                    onChange={handleInputChange}
                    value={values.notes}
                    labelOn={true}
                    rows={4}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="Part replaces"
                  name="replacedPartNumber"
                  onChange={handleInputChange}
                  value={values.replacedPartNumber}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="Fits models"
                  name="models_fit"
                  onChange={handleInputChange}
                  value={values.models_fit}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="VV Part replaces"
                  name="vv_replacedPartNumber"
                  onChange={handleInputChange}
                  value={values.vv_replacedPartNumber}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="VV Fits models"
                  name="vv_models_fit"
                  onChange={handleInputChange}
                  value={values.vv_models_fit}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="images"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {values.diagram_number
                      ? "VV Diagram " + values.diagram_number
                      : "VV Diagram: Not available"}
                  </label>
                </div>
                <div className="mt-1">
                  {values.vv_diagram_url ? (
                    <a
                      href={values.vv_diagram_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {values.vv_diagram_url}
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="images"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Images
                  </label>
                </div>
                <div className="mt-1">
                  {product ? (
                    <ul>
                      {product.pictures.map((picture) => (
                        <li key={picture.id}>
                          <a
                            href={picture.imageUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {picture.imageUrl}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div className="mt-3 grid grid-cols-1 gap-4">
                <div>
                  <Input
                    label="Image source url"
                    name="image_src_url"
                    onChange={handleInputChange}
                    value={values.image_src_url}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <Input
                  label="Homedepot url"
                  name="homedepot_url"
                  onChange={handleInputChange}
                  value={values.homedepot_url}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="Amre url"
                  name="amre_url"
                  onChange={handleInputChange}
                  value={values.amre_url}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="VV Appliances url"
                  name="vv_appliances_url"
                  onChange={handleInputChange}
                  value={values.vv_appliances_url}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            {isEdit ? (
              <div className="flex mt-4">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                  onClick={() => startScrape(product.partNumber)}
                  disabled={scrapeInProgress}
                >
                  {scrapeInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Scrape"
                  )}
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-500 items-center ml-4"
                  onClick={() => starMetadataSync(product.partNumber)}
                >
                  {metadataInSync ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Sync Meta"
                  )}
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-500 items-center ml-4"
                  onClick={() => openChatGptForm(product.partNumber)}
                >
                  Chat Gpt
                </button>
              </div>
            ) : null}
          </div>
          <div className="mt-3 grid grid-cols-1 gap-4">
            <div>
              <p>{formResult}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
