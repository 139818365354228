import { useState } from "react";

import { makePostApiCall } from "../shared/makeApiCall";

export function UserViewModel(logout) {
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem("id");

  const updateAccountPassword = async function (values) {
    setLoading(true);
    let callResult = await makePostApiCall(`users/password/${userId}`, values);

    setLoading(false);
    if (callResult.success) {
      //   setProducts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return { updateAccountPassword };
}
